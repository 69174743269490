<template>
  <!-- <Modal v-model="isShow"
         class-name="i-modal"
         title="奖金明细"
         width="1000px"> -->
  <Drawer
    title="奖金明细"
    class="order_detail_drawer"
    v-model="isShow"
    width="1000"
    scrollable
    draggable
  >
    <div class="main-container">
      <!--    搜索块-->
      <Form
        ref="SearchForm"
        class="search-form-container"
        :model="params"
        label-position="left"
        :label-width="70"
      >
        <div class="search-form-container__content">
          <Row :gutter="16">
            <Col span="6">
              <FormItem label="业务编号:" prop="BusinessCode">
                <Input
                  placeholder="请输入业务编号"
                  v-model="params.BusinessCode"
                  clearable
                />
              </FormItem>
            </Col>
            <Col span="6">
              <FormItem label="奖金类型:" prop="Type">
                <Select clearable v-model="params.Type" placeholder="奖金类型">
                  <Option
                    v-for="item in BonusTypeEnum"
                    :value="item.ID"
                    :key="item.ID"
                  >
                    {{ item.CN }}
                  </Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="6">
              <FormItem label="日期范围:" prop="TempDate">
                <Date-picker
                  style="width: 100%"
                  clearable
                  v-model="params.TempDate"
                  ref="date"
                  type="datetimerange"
                  placement="bottom-end"
                  placeholder="日期范围"
                  format="yyyy-MM-dd HH:mm:ss"
                  @on-change="dateUpdate"
                ></Date-picker>
              </FormItem>
            </Col>
            <Col span="6" class="mt5">
              <Button
                class="ml2"
                size="small"
                type="primary"
                icon="ios-search"
                @click="handleSearch"
              >
                搜索
              </Button>
              <Button class="ml2" size="small" @click="handleReset">
                重置
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
      <!--    table-->
      <Table
        class="mt10 main-table"
        :loading="tableLoading"
        :height="tableHeight"
        ref="dataTable"
        :columns="columns"
        :data="resObj.DataList"
        highlight-row
        size="small"
        stripe
      >
        <template slot="State" slot-scope="{ row }">
          <Tag
            type="border"
            :color="
              row.State === 0
                ? 'success'
                : row.State === 1
                ? 'volcano'
                : row.State === 2
                ? 'magenta'
                : row.State === 3
                ? 'red'
                : row.State === 4
                ? 'warning'
                : 'default'
            "
          >
            {{ row.MemberStateCN }}
          </Tag>
        </template>

        <template slot="ReferrerCode" slot-scope="{ row }">
          <span
            style="color: #19be6b; cursor: pointer"
            @click="handleShowReferrerUser(row.ReferrerCode)"
          >
            {{ row.ReferrerCode }}
          </span>
        </template>

        <template slot="BusinessCode" slot-scope="{ row }">
          <Span
            style="color: green; cursor: pointer"
            @click="openOrderDrawer(row, true)"
          >
            {{ row.BusinessCode }}
          </Span>
        </template>
        <template slot="Action" slot-scope="{ row }">
          <Button
            class="ml2"
            size="small"
            type="primary"
            v-if="row.Type === 101 || row.Type === 102 || row.Type === 103"
            @click="achiveDrawer(row)"
          >
            查看明细
          </Button>
        </template>
      </Table>
      <!--    分页-->
      <Row class="mt10">
        <Col span="24" class="flex-end">
          <Page
            :page-size-opts="[40, 60, 80, 100]"
            :page-size="40"
            :total="resObj.TotalCount"
            :current="resObj.CurrentPage"
            @on-page-size-change="handleChangePageSize"
            @on-change="handleChangePage"
            size="small"
            show-elevator
            show-sizer
            show-total
          />
        </Col>
      </Row>
    </div>

    <div slot="footer">
      <Button @click="isShow = false">取消</Button>
    </div>
    <!-- </modal> -->
  </Drawer>
</template>

<script>
import api from "@/api";
import bizEnum from "@/libs/bizEnum";
export default {
  name: "bonus-list-detail",
  data() {
    return {
      isShow: false,
      tableHeight: 0,
      tableLoading: false,
      TempDate: [],
      id: "",
      params: {
        BusinessCode: "",
        Type: "",
        BeginDate: "",
        EndDate: "",
        Page: 1,
        PageSize: 40,
        SortName: "ID",
        SortOrder: "Desc",
      },
      columns: [
        {
          title: "业务编号",
          key: "BusinessCode",
          slot: "BusinessCode",
          sortable: "custom",
          align: "center",
          width: 180,
        },
        {
          title: "商品名",
          key: "ProductName",
          align: "left",
          minWidth: 120,
        },
        {
          title: "商品数量",
          key: "ProductCount",
          align: "center",
          minWidth: 80,
        },
        {
          title: "获得奖金",
          key: "Amount",
          sortable: "custom",
          align: "center",
          minWidth: 80,
        },
        { title: "奖金类型", key: "TypeCN", align: "center", minWidth: 100 },
        // {
        //   title: "余额",
        //   key: "OriginalAmount",
        //   align: "center",
        //   minWidth: 100,
        // },
        {
          title: "获得时间",
          key: "CreateDate",
          sortable: "custom",
          align: "center",
          minWidth: 150,
        },
        // {
        //   title: "操作",
        //   key: "action",
        //   slot: "Action",
        //   align: "center",
        //   fixed: "right",
        //   minWidth: 55,
        //   maxWidth: this.$route.meta.tableActionWidth,
        // },
      ],
      resObj: {
        CurrentPage: 1,
        TotalCount: 0,
        TotalPage: 0,
        DataList: [],
      },
      // 日期快捷选择
      options: {
        shortcuts: [
          {
            text: "最近一周",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              return [start, end];
            },
          },
          {
            text: "最近一个月",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              return [start, end];
            },
          },
          {
            text: "最近三个月",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              return [start, end];
            },
          },
        ],
      },
    };
  },
  computed: {
    BonusTypeEnum() {
      return bizEnum.BonusTypeEnum;
    },
  },
  mounted() {
    const self = this;
    self.$nextTick(() => {
      self.tableHeight = self.$getTableHeight("main-container");
    });
    window.onresize = function () {
      self.$nextTick(() => {
        self.tableHeight = self.$getTableHeight("main-container");
      });
    };
  },
  watch: {
    isShow: function (val) {
      if (val && this.id !== -1) {
        this.loadList();
      }
    },
  },
  methods: {
    handleChangePageSize(pagesize) {
      this.params.Page = 1;
      this.params.PageSize = pagesize;
      this.loadList();
    },
    handleChangePage(page) {
      this.params.Page = page;
      this.loadList();
    },
    handleSearch() {
      this.params.Page = 1;
      this.loadList();
    },
    handleReset() {
      this.params.Name = "";
      this.$refs.SearchForm.resetFields();
      this.loadList();
    },
    // 远程排序
    SortChange(result) {
      this.params.SortName = result.key;
      this.params.SortOrder = this.params.SortOrder === "Desc" ? "Asc" : "Desc";
      this.loadList();
    },
    // 日期变化方法
    dateUpdate(value) {
      this.params.BeginDate = value[0];
      this.params.EndDate = value[1];
    },
    async openOrderDrawer(row) {
      try {
        let res = await api.GetOrderByCode({
          code: row.BusinessCode,
        });
        if (res.Data) {
          // this.$refs.orderDetailDrawer.obj = res.Data;
          // this.$refs.orderDetailDrawer.isShow = true;
          // this.$refs.orderDetailDrawer.isEdit = false;
          this.$emit("showOrderDetailDrawer", res.Data);
        }
      } catch (error) {
        this.$Notice.error({ title: "错误", desc: error });
      }
    },
    async loadList() {
      this.tableLoading = true;
      this.params.MemberCode = this.id;
      try {
        const res = await api.GetBonusDetailList(this.params);
        this.resObj = res.Data;
        this.tableLoading = false;
      } catch (err) {
        this.tableLoading = false;
        this.$Notice.error({ title: "错误", desc: err });
      }
    },
    achiveDrawer(row) {
      const params = {
        MemberCode: row.MemberCode,
        BeginDate: `${this.$moment(row.CreateDate)
          .subtract(1, "month")
          .format("YYYY-MM")}-01`,
        EndDate: `${this.$moment(row.CreateDate).format("YYYY-MM")}-01`,
      };
      if (row.Type === 101) {
        params.Type = "10,40";
      }
      if (row.Type === 102) {
        params.Type = "20,50";
        params.TeamID = row.TeamMemberCode;
      }
      if (row.Type === 103) {
        params.Type = "30,60";
      }
      this.$parent.$refs.achieveDrawer.params = params;
      this.$parent.$refs.achieveDrawer.isShow = true;
    },
  },
};
</script>

<style lang="less"></style>
