<template>
  <Layout class="main-container">
    <!--    搜索块-->
    <Form
      ref="SearchForm"
      class="search-form-container"
      :model="params"
      label-position="left"
      :label-width="80"
    >
      <Row :gutter="16">
        <Col span="6">
          <FormItem label="会员编号:" prop="MemberCode">
            <Input
              placeholder="请输入会员编号"
              v-model="params.MemberCode"
              clearable
            />
          </FormItem>
        </Col>
        <Col span="6">
          <FormItem label="会员名称:" prop="MemberName">
            <Input
              placeholder="请输入会员名称"
              v-model="params.MemberName"
              clearable
            />
          </FormItem>
        </Col>
        <!-- <Col span="6">
          <FormItem label="手机号:" prop="Mobile">
            <Input
              placeholder="请输入会员手机号"
              v-model="params.Mobile"
              clearable
            />
          </FormItem>
        </Col> -->
        <Col span="8" class="mt5">
          <Button
            class="ml2"
            size="small"
            type="primary"
            icon="ios-search"
            @click="handleSearch"
          >
            搜索
          </Button>
          <Button class="ml2" size="small" @click="handleReset"> 重置 </Button>
          <Button
            type="success"
            class="ml2"
            size="small"
            icon="md-cloud-download"
            @click="exportData"
          >
            导出
          </Button>
        </Col>
      </Row>
    </Form>
    <!--    table-->
    <Table
      class="mt10 main-table"
      :loading="tableLoading"
      :height="tableHeight"
      ref="dataTable"
      :columns="columns"
      :data="resObj.DataList"
      highlight-row
      size="small"
      @on-sort-change="sortChange"
      stripe
    >
      <template slot="Amount" slot-scope="{ row }">
        <p v-if="!row.isEdit">{{ row.Amount }}</p>
        <Input v-model="row.Amount" v-else />
      </template>
      <template slot="DrawBonus" slot-scope="{ row }">
        <p v-if="!row.isEdit">{{ row.DrawBonus }}</p>
        <Input v-model="row.DrawBonus" v-else />
      </template>
      <template slot="TotalAmount" slot-scope="{ row }">
        <p v-if="!row.isEdit">{{ row.TotalAmount }}</p>
        <Input v-model="row.TotalAmount" v-else />
      </template>
      <template slot="MemberView" slot-scope="{ row }">
        <span
          style="color: green; cursor: pointer"
          @click="handleOpenUser(row.MemberCode)"
        >
          {{ row.MemberCode }}
        </span>
      </template>

      <template slot="Action" slot-scope="{ row }">
        <Button
          size="small"
          type="primary"
          @click="openDrawer(row)"
          v-if="!row.isEdit && $canAction(['Bonus_Search'])"
        >
          查看
        </Button>
        <!-- <Button
          class="ml2"
          size="small"
          type="success"
          @click="handleEdit(row)"
          v-if="!row.isEdit && $canAction(['Bonus_Edit'])"
        >
          修改
        </Button>
        <Button
          class="ml2"
          size="small"
          type="warning"
          @click="handlBonus(row)"
          v-if="
            !row.isEdit && row.MemberLevelID == 80 && $canAction(['Bonus_Edit'])
          "
        >
          提成
        </Button> -->
        <!-- <Button size="small"
                type="info"
                v-if="row.isEdit && $canAction(['MBonus_Edit'])"
                @click="handleSave(row)"
                :loading="isSave">保存</Button>
        <Button size="small"
                class="ml2"
                type="error"
                v-if="row.isEdit && $canAction(['MBonus_Edit'])"
                @click="handleCancel(row)">取消</Button> -->
      </template>
    </Table>
    <!--    分页-->
    <Row class="mt10">
      <Col span="24" class="flex-end">
        <Page
          :total="resObj.TotalCount"
          :current="resObj.CurrentPage"
          @on-page-size-change="handleChangePageSize"
          @on-change="handleChangePage"
          :page-size-opts="[40, 60, 80, 100]"
          :page-size="40"
          size="small"
          show-elevator
          show-sizer
          show-total
        />
      </Col>
    </Row>

    <Modal
      class-name="i-modal"
      v-model="BonusView"
      width="500"
      title="编辑奖金"
      loading
      :maskClosable="false"
    >
      <Form :label-width="90">
        <FormItem label="可提奖金:" prop="Amount">
          <Input type="number" v-model="Amount" />
        </FormItem>
        <FormItem label="已提奖金:" prop="DrawBonus">
          <Input type="number" v-model="DrawBonus" />
        </FormItem>
        <FormItem label="总奖金:" prop="TotalAmount">
          <Input type="number" v-model="TotalAmount" />
        </FormItem>
      </Form>
      <template slot="footer">
        <Button @click="BonusView = false">取消</Button>
        <Button type="primary" :loading="BonusLoading" @click="handleBonus">
          保存
        </Button>
      </template>
    </Modal>
    <!--    明细信息展示抽屉-->
    <BonusDetailList
      ref="bonusDetailDrawer"
      @showOrderDetailDrawer="showOrderDetailDrawer"
    />
    <!--    用户信息编辑抽屉-->
    <UserProfileDrawer ref="userDrawer" />
    <!--    订单明细信息展示抽屉-->
    <order-detail-drawer ref="orderDetailDrawer" />
  </Layout>
</template>

<script>
import api from "@/api";
import bizEnum from "@/libs/bizEnum";
import Layout from "@/components/layout/index.vue";
import UserProfileDrawer from "../member/components/member-drawer.vue";
import BonusDetailList from "./components/bonus-list-detail.vue";
import OrderDetailDrawer from "../order/components/detail-drawer.vue";
import moment from "moment";
export default {
  name: "BonusList",
  data() {
    return {
      BonusView: false,
      tableLoading: false,
      tableHeight: 0,
      RowID: 0,
      Amount: 0,
      DrawBonus: 0,
      TotalAmount: 0,
      BonusLoading: false,
      params: {
        MemberCode: "",
        MemberName: "",
        Page: 1,
        PageSize: 40,
        SortName: "ID",
        SortOrder: "Desc",
      },
      columns: [
        {
          title: "会员编号",
          sortable: "custom",
          key: "MemberCode",
          slot: "MemberView",
          minWidth: 90,
        },
        { title: "会员名称", key: "MemberName", minWidth: 120 },
        { title: "会员身份", key: "MemberIdentityCN", minWidth: 95 },
        { title: "会员电话", key: "Mobile", minWidth: 95 },
        {
          title: "可提奖金",
          sortable: "custom",
          key: "Amount",
          slot: "Amount",
          minWidth: 90,
        },
        {
          title: "已提奖金(含申请中)",
          sortable: "custom",
          key: "DrawBonus",
          slot: "DrawBonus",
          minWidth: 90,
        },
        {
          title: "总奖金",
          sortable: "custom",
          key: "TotalAmount",
          slot: "TotalAmount",
          minWidth: 90,
        },
      ],
      resObj: {
        CurrentPage: 1,
        TotalCount: 0,
        TotalPage: 0,
        DataList: [],
      },
      showUserProfileDrawer: false,
      rowCopy: null,
    };
  },
  computed: {
    memberStateEnum() {
      return bizEnum.MemberStatusEnum;
    },
    MemberLevelEnum() {
      return bizEnum.MemberLevelEnum;
    },
  },
  created() {
    if (this.$route.meta.tableActionWidth) {
      this.columns.push({
        title: "操作",
        key: "action",
        slot: "Action",
        align: "right",
        fixed: "right",
        minWidth: this.$route.meta.tableActionWidth,
        maxWidth: this.$route.meta.tableActionWidth,
      });
    }
    this.loadMemberBonusList();
  },
  mounted() {
    const self = this;
    self.$nextTick(() => {
      self.tableHeight = self.$getTableHeight("main-container");
    });
    window.onresize = function () {
      self.$nextTick(() => {
        self.tableHeight = self.$getTableHeight("main-container");
      });
    };
    document.onkeydown = function (event) {
      var e = event || window.event;
      if (self.$route.name === self.$options.name && e && e.keyCode === 13) {
        // enter 键
        self.loadMemberBonusList();
      }
    };
    this.$bus.$on("refreshPage", () => {
      if (this.$route.name === this.$options.name) {
        this.params && (this.params = this.$options.data().params);

        this.loadMemberBonusList();
      }
    });
  },
  activated() {
    const self = this;
    document.addEventListener("keydown", function (event) {
      var e = event || window.event;
      if (self.$route.name === self.$options.name && e && e.keyCode === 13) {
        // enter 键
        self.loadMemberBonusList();
      }
    });
  },
  deactivated() {
    document.removeEventListener("keydown", function () {});
  },
  methods: {
    exportData() {
      try {
        this.$refs.dataTable.exportCsv({
          filename: "会员奖金 " + moment().format("YYYY-HH-MM hh点mm分ss秒"),
          columns: this.columns.filter((col, index) => index < 7),
          data: this.resObj.DataList,
        });
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
        // TODO: 记录错误日志
      }
    },
    showOrderDetailDrawer(data) {
      this.$refs.orderDetailDrawer.obj = data;
      this.$refs.orderDetailDrawer.isShow = true;
      this.$refs.orderDetailDrawer.isEdit = false;
    },
    handleChangePageSize(pagesize) {
      this.params.Page = 1;
      this.params.PageSize = pagesize;
      this.loadMemberBonusList();
    },
    handleChangePage(page) {
      this.params.Page = page;
      this.loadMemberBonusList();
    },
    async loadMemberBonusList() {
      this.tableLoading = true;
      try {
        const res = await api.GetMemberBonusList(this.params);
        res.Data.DataList.forEach((item) => {
          item.isEdit = false;
          // if (item.MemberLevelID) {
          //   item.MemberLevelCN = _this.MemberLevelEnum.find(it => it.ID === item.MemberLevelID).CN;
          // }
        });
        this.resObj = res.Data;
        this.tableLoading = false;
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
        this.tableLoading = false;
        // TODO: 记录错误日志
      }
    },
    handleSearch() {
      this.params.Page = 1;
      this.loadMemberBonusList();
    },
    handleReset() {
      this.$refs.SearchForm.resetFields();
      this.loadMemberBonusList();
    },
    openDrawer(row) {
      this.$refs.bonusDetailDrawer.id = row.MemberCode;
      // this.$refs.bonusDetailDrawer.obj = row;
      this.$refs.bonusDetailDrawer.isShow = true;
    },
    handleCancel(row) {
      const index = this.resObj.DataList.findIndex(
        (item) => item.ID === row.ID
      );
      if (index !== -1) {
        this.resObj.DataList.splice(index, 1, this.rowCopy);
      }
    },
    handleEdit(row) {
      // this.rowCopy = JSON.parse(JSON.stringify(row));
      // row.isEdit = true;
      this.RowID = row.ID;
      this.Amount = row.Amount;
      this.DrawBonus = row.DrawBonus;
      this.TotalAmount = row.TotalAmount;
      this.BonusView = true;
    },
    handlBonus(row) {
      this.RowID = row.ID;
    },
    // 远程排序
    sortChange(result) {
      this.params.SortName =
        result.key === "MemberLevelCN" ? "MemberLevelID" : result.key;
      this.params.SortOrder = this.params.SortOrder === "Desc" ? "Asc" : "Desc";
      this.loadMemberBonusList();
    },
    async handleSave(row) {
      this.isSave = true;
      try {
        await api.SaveMemberBonus({
          id: row.ID,
          amount: row.Amount,
          drawBonus: row.DrawBonus,
          totalAmount: row.TotalAmount,
        });
        row.isEdit = false;
        this.$Message.success("保存成功");
        this.isSave = false;
      } catch (err) {
        this.isSave = false;
        this.$Notice.error({
          title: "错误",
          desc: err,
        });
      }
    },
    async handleBonus() {
      this.BonusLoading = true;
      try {
        await api.SaveMemberBonus({
          id: this.RowID,
          amount: this.Amount,
          drawBonus: this.DrawBonus,
          totalAmount: this.TotalAmount,
        });
        this.BonusLoading = false;
        this.$Message.success("操作成功");
        const temp = this.resObj.DataList.find(
          (item) => item.ID === this.RowID
        );
        temp.Amount = this.Amount;
        temp.DrawBonus = this.DrawBonus;
        temp.TotalAmount = this.TotalAmount;
        this.BonusView = false;
      } catch (err) {
        this.BonusLoading = false;
        this.$Notice.error({
          title: "错误",
          desc: err,
        });
      }
    },
    async handleOpenUser(MemberID) {
      try {
        const res = await api.GetMemberByCode({
          code: MemberID,
        });
        if (res.Status === 100) {
          this.$refs.userDrawer.obj = res.Data;
          this.$refs.userDrawer.isShow = true;
        } else {
          this.$Message.error(res.Msg);
        }
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
        this.tableLoading = false;
        // TODO: 记录错误日志
      }
    },
    // 修改成功，更改列表数据
    handleEditSuccess(res) {
      const index = this.resObj.DataList.findIndex(
        (item) => item.ID === res.ID
      );
      this.resObj.DataList.splice(index, 1, res);
    },
  },
  components: {
    OrderDetailDrawer,
    UserProfileDrawer,
    BonusDetailList,
    Layout,
  },
};
</script>
